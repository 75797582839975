import { Box } from '@mui/material'
import React from 'react'
import Reviewspage from './Reviewspage'

function Testimonialspage() {
  return (
    <Box component={"div"} sx={{bgcolor:"grey",height:"100vh"}}>
       <Reviewspage/>
   </Box>
  )
}

export default Testimonialspage