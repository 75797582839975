import React from 'react'
import "./Carousel.css"
import Carousel from 'react-bootstrap/Carousel';
function Carrousel() {
  return (
    <div>
       <Carousel
       style={{padding:"",height:""}}
       fade
       >
      <Carousel.Item
       interval={2000}
       
       >
        <img
         src='/Images/graduation-day-joy-stockcake.jpg'
         alt='logo'
         className='carousel_img'
        />
      </Carousel.Item>
      <Carousel.Item
       interval={2000}
      
      >
      <img
         src='/Images/officer-monitoring-screens-stockcake.jpg'
         alt='logo'
          className='carousel_img'
        />
      </Carousel.Item>
      <Carousel.Item
       interval={2000}
      
      >
      <img
         src='/Images/kids-going-school-stockcake.jpg'
         alt='logo'
          className='carousel_img'
        />
      </Carousel.Item>
    </Carousel>
    </div>
    
  )
}

export default Carrousel