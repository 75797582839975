import { Box } from '@mui/material'
import React from 'react'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';
import Grid from '@mui/material/Grid';
import {Typography } from '@mui/material';

export default function FooterContactUs() {
  return (
    <Box className="footer_contactInfor"
    >
       <Grid container
   sx={{
    bgcolor:"lightcyan",
    // paddingRight:0.5
  
   }}
>
    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}
                sx={{
                bgcolor:"",
                display:"flex",
                alignItems:"center",
                justifyContent:"start",
                borderRight:"1px solid grey",
                borderBottom:"1px solid grey"
                }}
        >
            <Grid
            sx={{
                padding:2,
            }}
            >
            <span
                style={{
                paddingLeft:22
                }}
            >
                <PhoneInTalkIcon
                sx={{
                color:"#64686D",
                }}
                />   
            </span>
            <Typography variant="h6" 
            sx={{
                color:"black",
            }}
            
            >
                Call us on:
            </Typography>
            </Grid>
            <Grid component={'div'}
            sx={{
            //  marginLeft:"10px"
            }}
            >
            <Typography
                sx={{
                color:"black",
                paddingTop:3.5
                }}
                variant='h5'

            >
                07 xx xx xx xx
            </Typography>
            </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}
                sx={{
                bgcolor:"",
                display:"flex",
                alignItems:"center",
                justifyContent:"start",
                borderRight:"1px solid grey",
                borderBottom:"1px solid grey"

                }}
        >
            <Grid
            sx={{
                padding:2,
            }}
            >
            <span
                style={{
                paddingLeft:22
                }}
            >
                <LocationOnSharpIcon
                sx={{
                color:"#64686D",

                }}
                />   
            </span>
            <Typography  
            sx={{
                color:"black",
                
            }}
            variant='h6'
            >
                Visit us at:
            </Typography>
            </Grid>
            <Grid component={'div'}
            sx={{
                // marginLeft:"10px"
            }}
            >
            <Typography
                sx={{
                color:"black",
                paddingTop:0
                }}
                variant='h6'

            >
                North Star Office Appts, 

            </Typography>
            <Typography
                sx={{
                color:"black",
                paddingTop:0
                }}
                variant='h6'

            >
            Lenana Road Kilimani-Nairobi

            </Typography>
            <Typography
                sx={{
                color:"black",
                paddingTop:0
                }}
                variant='h6'

            >
            P.O. Box 25638-00603

            </Typography>
            </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}
                sx={{
                bgcolor:"",
                display:"flex",
                alignItems:"center",
                justifyContent:"start",
                borderRight:"1px solid grey",
                borderBottom:"1px solid grey"
                }}
        >
            <Grid
            sx={{
                padding:1.5,
            }}
            >
            <span
                style={{
                paddingLeft:20
                }}
            >
                <EmailSharpIcon
                sx={{
                color:"#64686D",
                }}
                />   
            </span>
            <Typography variant="h6" 
            sx={{
                color:"black",
            }}
            
            >
                Email us :
            </Typography>
            </Grid>
            <Grid component={'div'}>
            <Typography
                sx={{
                color:"black",
                paddingTop:3.5,
                paddingRight:1,
                width:"fit-content"
                }}
                variant='h5'

            >
                info@afcesss.org
            </Typography>
            </Grid>
        </Grid>
        </Grid>
    </Box>
  )
}
