import { Box } from '@mui/material'
import React from 'react'

export default function NotfoundPage() {
  return (
    <Box component={"div"} sx={{bgcolor:"grey",height:"100vh"}}>
      Not found page
   </Box>
  )
}
