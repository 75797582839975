import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { Divider, Stack, Typography } from '@mui/material'
import ContactUsForm from './ContactUsForm'
import VisionPage from './VisionPage'

export default function ContactUsPage() {
  return (
    <Box sx={{}}>
      <Grid>
      <img
            style={{
              width: "100%",
              height: "30vw",
              // objectFit:"fill"
            }}
             src='Images/best-contact-us-page-examples.webp'
             alt='Contact Us'
            //  acsss/public/Images/best-contact-us-page-examples.webp
            />
      </Grid>
      <Grid
        sx={{display:"flex",alignItems:"center",justifyContent:"center",bgcolor:"lightgrey"}}
      >
      <Typography sx={{letterSpacing:10,textTransform:"uppercase",fontSize:"30px",}}><b>Contact us</b></Typography>
      </Grid>
      <Divider/>
      <Grid container spacing={0} sx={{bgcolor:"#fff",height:"fit-content"}}>
        
        {/* <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{bgcolor:"aliceblue",height:"100vh"}}>
          <Paper variant="elevation" elevation="10">
              
            
          </Paper>
        </Grid> */}
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5} sx={{bgcolor:"",}}>
           <Box component={"div"} sx={{paddingTop:1,paddingLeft:6,borderLeft:"1px solid black",height:"100%",borderRight:"1px solid #478d94"}}>
             <Stack>
              <Typography 
               variant='h5' sx={{
                color:"#478d94",
                fontWeight:"bold",
                textDecoration:"underline",
                letterSpacing:2.5,
                marginBottom:"8px"
              }}
              >
                 Physical Address
              </Typography>
             </Stack>
             <Stack sx={{
              paddingLeft:0
             }}
             spacing={4}
             >
              <Stack>
                <Typography
                sx={{
                  color:"#64686D"
                }}
                >
                North Star Office Appts, Lenana Road Kilimani-Nairobi
                </Typography>
              </Stack>
              <Stack>
                <Typography>
                P.O. Box 25638-00603
                </Typography>
              </Stack>
              <Stack direction={'row'}>
              <Typography>
                Tel: <b>07 xx xx xx xx</b>
                </Typography>
              </Stack>
              <Stack
              >
                <Typography>
                Cell Phone: <b>07 xx xx xx xx</b>
                </Typography>
               
                  
     
              </Stack>
              <Stack>
                <Typography>
                Email: <b>info@afcesss.org.</b>
                </Typography>
              </Stack>
             </Stack>
           </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7} sx={{bgcolor:"",height:"fit-content"}}>
          <Box elevation={0} component={"div"} sx={{bgcolor:"",height:"100%",padding:1}}>
           <Stack>
            <Typography variant='h5' sx={{
              color:"#478d94",
              fontWeight:"bold",
              textDecoration:"underline",
              letterSpacing:2.5,
               marginBottom:"8px"
            }}>
              Send us a message now
              </Typography>
              <Typography component={"p"} sx={{
                color:"#64686D",
                marginBottom:"10px"
              }}>
               For any enquiry kindly fill out the form below and we will get back to you shortly.
              </Typography>
           </Stack>
           <Paper elevation={0} sx={{paddingBottom:3}}>
             <ContactUsForm/>
           </Paper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
