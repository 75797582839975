
import React, { useEffect, useState } from 'react';
import { AppBar, Box, Button, Divider, IconButton, Menu, MenuItem, Paper, Toolbar, Typography } from '@mui/material';
import { Link, NavLink } from 'react-router-dom';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import './Header.css';
// acsss/src/Front/Components/LayoutComponents/Header.css
const navItems = [
  { path: "/", label: "Home" },
  { path: "/about_us", label: "About Us", subItems: [
      { path: "/about_us/who_we_are", label: "Who We Are" },
      { path: "/about_us/our_mandate", label: "Our Mandate" },
      { path: "/about_us/leader_ship", label: "Leadership" },

    ] 
  },
  { path: "/our_services", label: "Our Services",subItems:[
    { path: "/our_services/intelligence_analysis_&_research", label: "Intelligence Analysis and Research" },
    { path: "/our_services/environmental_sciences_and_climate_change_resilience", label: "Environmental Sciences and Climate Change Resilience" },
    { path: "/our_services/counterterrorism_terrorism_financing_prevention_of _violent_extremism", label: "Counterterrorism,Terrorism Financing Prevention of Violent Extremism" },
  ]
 },
 {path:"/reviews", label: "testimonials" },

 {path:"/contact_us", label:"contact us"},

];

function Header() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentSubMenu, setCurrentSubMenu] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const handleScroll = () => {
    if (window.scrollY === 0) {
      setIsScrolled(false);
    } else {
      setIsScrolled(true);
    }
  };
   
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleHover = (event, subItems) => {
    setAnchorEl(event.currentTarget);
    setCurrentSubMenu(subItems);
  };

  const handleClick = (event, subItems) => {
    if (anchorEl) {
      handleClose();
    } else {
      setAnchorEl(event.currentTarget);
      setCurrentSubMenu(subItems);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentSubMenu(null);
  };
  return (
    <Box>
      <Divider/>
      <AppBar position='static' sx={{bgcolor: isScrolled ? "lightgrey":"transparent",p:0,}} elevation={1}>
        <Toolbar sx={{p:0,display:{xs:"block",md:"block",sm:"block",lg:"flex"},}} >
            <Box 
              sx={{
                // ":hover":{bgcolor:"#478d94",color:"white",},
                alignItems:"center",
                justifyContent:{xs:"center",md:"center",xl:"start",lg:"start",sm:"center"},
                display:{xs:"block",md:"flex",sm:"block",},
                mr:2,
                p:0,
                flexGrow:1
               }}>
              
                 <IconButton 
                 component="div"
                 size='large' 
                 edge={"start"}
                  color='inherit' 
                  aria-label='acsss-logo' 
                  disableRipple
                  sx={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                 }}>                
                      <img
                     src="/Images/flame-1964066_1280.png"
                     alt="acsss-logo"
                     style={{
                     width:51,
                     height:51,
                     borderRadius:10,
                    
                     }}
                      />
                    
                 </IconButton>
                 <Typography component={"div"} sx={{color:"brown",display:"flex",alignItems:"center",justifyContent:"center"}}>
                  <p className='acss-title' style={{}} >
                       AFRICAN CENTRE FOR SECURITY <br className='break-line'></br>AND STRATEGIC STUDIES(ACSSS)
                  </p>
                 </Typography>
            </Box>
            <Box
                sx={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-evenly"
                 }}
            >
            {navItems.map((item, index) => (
            item.subItems ? (
              <NavLink to={item.path} key={index}
                onMouseEnter={(e) => handleHover(e, item.subItems)}
                onMouseLeave={handleClose}
                sx={{
                    ":hover":{bgcolor:"#478d94",color:"white"}
                }}
              >
                    <Button 
                        className="nav-link-button"
                        variant='text' 
                        endIcon={<KeyboardArrowDown/>}
                        sx={{
                            color:"black",
                            mr:1,
                            ml:1,
                            height:50,
                            p:3,
                            fontSize:12,
                            ":hover":{bgcolor:"#478d94",color:"white"}}}
                            onMouseEnter={(e) => handleHover(e, item.subItems)}
                            onClick={(e) => handleClick(e, item.subItems)}
                    >
                            {item.label}
                    </Button>

                {/* <Button
                  endIcon={<KeyboardArrowDownIcon />}
                  sx={{ color: "white" }}
                  onClick={(e) => handleClick(e, item.subItems)}
                >
                  {item.label}
                </Button> */}
                
                    <Menu
                  
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl && currentSubMenu === item.subItems)}
                    onClose={handleClose}
                    MenuListProps={{
                    onMouseLeave: handleClose,
                    }}
                    >
                    {item.subItems.map((subItem, subIndex) => (
                        <MenuItem 
                          key={subIndex}
                          component={Link}
                          to={subItem.path}
                          onClick={handleClose}
                          sx={{
                              ":hover":{bgcolor:"#478d94",color:"white"},
                              display:"flex",
                              alignItems:"center",
                              justifyContent:"start",
                              // bgcolor:"#478d94"
                              
                              
                          }}
                          >
                          {subItem.label}
                      
                        </MenuItem>
                       
                    ))}
                    </Menu>
                
               
              </NavLink>
            ) : (
                <NavLink
                   to={item.path}
                   key={index}
                  >
                    <Button variant='text' sx={{color:"black",mr:1,ml:1,height:50,p:3.5,fontSize:12,":hover":{bgcolor:"#478d94",color:"white"}}} className='nav-link-button'>{item.label}</Button>
                 </NavLink>
            )
          ))}
            </Box>
    
       
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;
