import { Box } from '@mui/material'
import React from 'react'

export default function WhoWeArePage() {
  return (
    
    <Box 
     sx={{
      height:"100vh",
      bgcolor:"lightcyan"
     }}
    >

    </Box>
  )
}
