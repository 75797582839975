import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';


import Grid from '@mui/material/Grid';
import React from 'react'
import './Footer.css';
import { Stack, Typography } from '@mui/material';
import FooterContactUs from '../../Pages/FooterContactUs';

const findUs_Arr=[
  {icon:PhoneInTalkIcon,title:"Call us on:",content:"07 xx xx xx xx"},
  {icon:"<LocationOnSharpIcon/>",title:"Visit us at:",content:"North Star Office Appts, Lenana Road Kilimani-Nairobi P.O. Box 25638-00603"},
  {icon:"<EmailSharpIcon/>",title:"Email us:",content:"info@afcesss.org"},

]
export const Footer = () => {
  return (
<Box
 sx={{
  bgcolor:"#013e4b",
  height:"fit-content",
  position:"static",
  bottom:"0px",
  padding:"10px",
  paddingBottom:5
 }}
> 
<Box
 
 >
    <FooterContactUs/>

</Box>

<hr></hr>
</Box>
  )
}
