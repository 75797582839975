import { Box, Divider, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
const coreValuesArr=[
    {title:"Integrity: ",content:"ACSSS embraces transparency and accountability in all its operations. It subjects its work to the scrutiny of all through transparent procedures of planning and reporting of its activities. "},
    {title:"Compassion: ",content:"ACSSS  demonstrates high levels of empathy for the poor, marginalized and vulnerable in target communities and countries facing extreme security challenges. ACSSSrecognizes these categories as being most vulnerable to negative effects of insecurity. "},
    {title:"Diligence:",content:"ACSSS ensures that its procedures are pursued faithfully by the Governing structures. Its operations are underpinned by hard work and sacrifice among its staff."},
    {title:"Rule of Law: ",content:"ACSSS promotes respect for the law of the land in each state and the relevant international laws governing issues relevant to its mandate "},
    {title:"Human rights: ",content:": in conducting its work, ACSSS safeguards and promotes the rights of its target groups; it pursues a policy that prohibits discrimination on any grounds whatsoever. "},
    {title:"Gender equality and equity: ",content:"ACSSS recognizes different capacities and opportunities available to men and women, the youth and children as well as the historical, social and culturally constructed inequalities and insecurities existing amongst the people and the societies in general."},


]
function VisionPage() {
  return (
    <Box component={"div"}
    >
        <Paper>
            <Grid container
               sx={{
                borderTop:"1px solid #478d94",
                borderBottom:"1px solid #478d94",
                paddingTop:4,
             }}
            >
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                <Grid
                    sx={{
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center"
                    }}
                >
                    <Typography
                     sx={{
                        textDecoration:"2px underline #478d94"
                     }}
                    ><b>Our Vision</b></Typography>
                </Grid>
                <Grid sx={{
                    padding:2,
                    textAlign:"justify"
                }}>
                    <Typography
                     
                    >
                            To have a safe, secure and stable Eastern Africa and continental Africa for sustainable development, enhancement of democracy and dignity for all.
                    </Typography>
                </Grid>
                </Grid>   
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid 
                    sx={{
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center"
                    }}
                    >
                        <Typography
                             sx={{
                                textDecoration:"2px underline #478d94"
                             }}
                        ><b>Our Mission</b></Typography>
                    </Grid>
                    <Grid
                    sx={{
                        padding:2,
                         textAlign:"justify"
                    }}
                    >
                        <Typography>
                        ACSSS exists to promote through studies, research, policy advocacy, capacity building, situational analysis, dialogues, awareness creation, civic education and programmes, a sustainably secure and safe Africa for all, characterized by democratic and accountable leadership, democratic and accountable policing and citizen-focused security and military mechanisms.
                        </Typography>
                    </Grid>
                    
                </Grid>
            </Grid>
            <Grid container component={"div"}
               sx={{
                bgcolor:"lightgrey"
               }}
            >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid
                      sx={{
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center",
                        paddingTop:5
                      }}
                    >
                        <Typography
                             sx={{
                                textDecoration:"2px underline #478d94",
                                textTransform:"uppercase"
                             }}
                        >
                            <b>our Core Values</b>
                        </Typography>
                    </Grid>
                    <Grid
                     sx={{
                        textAlign:"justify",
                        padding:2
                     }}
                    >
                        <ul>
                            {
                                coreValuesArr.map((coreValue)=>{
                                    return(
                                        <li key={coreValue.title}>
                                            <Typography><b>{coreValue.title}</b></Typography>
                                            <Typography
                                             sx={{
                                                
                                             }}
                                            >{coreValue.content}</Typography>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </Grid>

                  
                </Grid>
            </Grid>
        </Paper>
    </Box>
    
  )
}

export default VisionPage