import React from 'react'
import {ErrorMessage, Field, Form, Formik, useFormik  } from "formik";
import { Box, Button, Stack } from '@mui/material';
import * as Yup from 'yup';


const yupValidationSchema=Yup.object({
    name: Yup.string().required("Required!"),
    email: Yup.string().email("Invalid email format").required("Required!"),
    subject:Yup.string().required("Required!"),
    message:Yup.string().required("Required!")
})
const initialvalues={
    name:"",
    email:"",
    subject:"",
    message:""
};


export const onsubmit=(values,onSubmitProps)=>{
    
    console.log(values)
   

    onSubmitProps.resetForm()
}
function ContactUsForm() {
    // const formik=useFormik({
    //     // ,
    //     // onSubmit:values=>{
    //     //     console.log(values);
    //     // },
    //     // validationSchema:yupValidationSchema,
    // })
    // console.log("form values",formik.values)

  return (
    <Box>
        <Formik
          initialValues={initialvalues}
          validationSchema={yupValidationSchema}
          onSubmit={onsubmit}
          
        >
            {({touched, errors,isValid,dirty,isSubmitting})=>(
              <Form >
                
              <Box
                  sx={{
                  marginBottom:"20px"
                  }}
              >
                  <Stack>
                      <label htmlFor='name'>Name</label>
                      <Field id='name' type='text' name='name' 
                         style={{
                            padding:"4px",
                            // borderRadius:"1%",
                            border:"1px solid #ccc",
                          }}
                      ></Field>  
                      {touched.name && errors.name && <ErrorMessage name="name" component="div" style={{ color: 'red' }} />} 
                  </Stack>
                  <Stack>
                      <label htmlFor='name'>Email</label>
                      <Field id='name' type='email' name='email'
                         style={{
                            padding:"4px",
                            border:"1px solid #ccc",

                          }}
                      ></Field>    
                      {touched.email && errors.email && <ErrorMessage name="email" component="div" style={{ color: 'red' }} />} 

                  </Stack>
                  <Stack>
                      <label htmlFor='name'>Subject</label>
                      <Field id='name' type='text' name='subject' placeholder="Eg.General Enquiry, Customer Support,Complaint,Report Corruption"
                      style={{
                        padding:"4px",
                        border:"1px solid #ccc",

                      }}
                      ></Field>
                      {touched.subject && errors.subject && <ErrorMessage name="subject" component="div" style={{ color: 'red' }} />} 


                  </Stack>
                  <Stack>
                      <label htmlFor='name'>Message</label>
                      <Field id='name' type='text' name='message' as="textarea" placeholder="Message" style={{
                        height: 100,
                        color:"#64686D",
                        padding:"5px",
                      }}></Field>    
                      {touched.message && errors.message && <ErrorMessage name="message" component="div" style={{ color: 'red' }} />} 
                  </Stack>
              </Box>
              <Box>
                  <Button variant='text'
                  sx={{
                      width:"100%",
                      ":hover":{bgcolor:"#478d94",color:"white"},
                      border:"1px solid #64686D "
                  }} 
                  type='submit'
                  disabled={!(isValid && dirty)}
                  >
                        {isSubmitting ? 'Sending...' : 'Send Message'}
                  </Button>
              </Box>
          </Form>
            )}
            
        </Formik>

    </Box>
  )
}

export default ContactUsForm