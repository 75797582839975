import { Box } from '@mui/material'
import React from 'react'

function OurMandatePage() {
  return (
    <Box 
     sx={{
      height:"100vh",
      bgcolor:"brown"
     }}
    >
    </Box>
  )
}

export default OurMandatePage