import React from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import VisionPage from './VisionPage'
import { Divider, Grid, Stack } from '@mui/material'
import Carrousel from '../Components/Commoncomponents/Carousel'


export default function Homepage() {
  return (
    <Box component={"div"}>
      <Grid
       sx={{
        marginBottom:"2px"
       }}
      >
          <Carrousel/>
      </Grid>
      <Divider sx={{olor:"black"}}/>
      <Grid>
        <VisionPage/>
      </Grid>
        
             
            
    
            
 
   
    </Box>
    
  )
}
