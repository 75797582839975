import { Box, Button, ButtonGroup, Grid, Paper } from '@mui/material'
import React from 'react'

function Topnavcomponent() {
  return (
    <Box
     component={"div"}
    >
     <Grid container>
        <Grid item sm={12} xs={12} md={12} lg={12} xl={12}
          sx={{

          }}
        >
            <Paper
              sx={{
                width:"100vw",
                bgcolor:"#013e4b",
                display:"flex",
                alignItems:"center",
                justifyContent:"space-between"
              }}
            >
               <ButtonGroup
                sx={{
                    bgcolor:"#013e4b",
                    width:"100%",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                }}
               >
                
                <Button
                  sx={{
                    color:"white",
                  }}
                >Facebook
                </Button>
                <Button
                   sx={{
                    color:"white",
                  }}
                >Email
                </Button>
                <Button
                   sx={{
                    color:"white",
                  }}
                >Phane
                </Button>

                </ButtonGroup>
            </Paper>
        </Grid>
     </Grid>
    </Box>
  )
}

export default Topnavcomponent