import { Box } from '@mui/material'
import React from 'react'

export default function OurServicesPage() {
  return (
    <Box component={"div"} sx={{bgcolor:"lightgrey",height:"100vh"}}>
     vvv
    </Box>
  )
}
