
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './Front/Components/LayoutComponents/Header';
import { Footer } from './Front/Components/LayoutComponents/Footer';
import Carrousel from './Front/Components/Commoncomponents/Carousel';
import { Box, Divider, Paper } from '@mui/material';
import { Route, Routes } from 'react-router';
import About_Us from './Front/Pages/About-Us';
import ContactUsPage from './Front/Pages/ContactUsPage';
import Testimonialspage from './Front/Pages/Testimonialspage';
import Homepage from './Front/Pages/Homepage';
import OurServicesPage from './Front/Pages/OurServicesPage';
import NotfoundPage from './Front/Pages/NotfoundPage';
import WhoWeArePage from './Front/Pages/WhoWeArePage';
import OurMandatePage from './Front/Pages/OurMandatePage';
import LeaderShipPage from './Front/Pages/LeaderShipPage';
import Dropdown from './Front/Components/DropdownItems/AboutUsDropDowns';
import Headerr from './Front/Components/DropdownItems/AboutUsDropDowns';
import Topnavcomponent from './Front/Components/LayoutComponents/Topnavcomponent';

function App() {
  return (
    <div className="App">
       <Box component={"div"} sx={{color:"black"}}>
        <Topnavcomponent/>
       </Box>
      <header className='header'>
      {/* <Headerr/> */}
         <Header/>
      </header>
        <main className='main_content'>
           <Routes>
            <Route path="/" element={<Homepage/>}/>
            <Route path="about_us">
              <Route path="who_we_are" element={<WhoWeArePage/>}/>
              <Route path="our_mandate" element={<OurMandatePage/>}/>
              <Route path="leader_ship" element={<LeaderShipPage/>}/>

            </Route>
            <Route path="our_services">
              <Route path="intelligence_analysis_&_research" element={<OurServicesPage/>}/>
              <Route path="environmental_sciences_and_climate_change_resilience" element={<OurServicesPage/>}/>
              <Route path="counterterrorism_terrorism_financing_prevention_of _violent_extremism" element={<OurServicesPage/>}/>
              <Route path="international_partnerships_and_events_organisations" element={<OurServicesPage/>}/>
              <Route path="cyber_security&artificial_intelligence_division" element={<OurServicesPage/>}/>

            </Route>
            <Route path="contact_us" element={<ContactUsPage/>}/>
            <Route path="reviews" element={<Testimonialspage/>}/>
           
            <Route path='*' element={<NotfoundPage/>}/>



           </Routes>
        </main>
        <footer className='footer'>
          <Paper>
            <Footer/>
          </Paper>   
        </footer>    
    </div>
  );
}

export default App;
