import React, { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import { Button, Grid, Paper, Typography } from '@mui/material';
import Slider from 'react-slick';

// Slick Slider CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Custom CSS for Slick Slider arrows
import '../Pages/slickCustomcss.css';

const reviewsArray = [
  {
    name: "George Co.Founder of ---",
    imgsrc: "Images/gerorge.jpg",
    review: "I recently had the pleasure of working with [Company Name], and I must say, the experience was nothing short of exceptional. From the initial consultation to the final delivery, every step of the process was handled with the utmost professionalism and attention to detail. The team took the time to understand my specific needs and provided tailored solutions that exceeded my expectations."
  },
  {
    name: "Frankline Managing Director of ---",
    imgsrc: "Images/flame-1964066_1280.png",
    review: "I highly recommend ACSSS to anyone looking for [specific service or product]. They have set a new standard for quality and customer service in the industry. I will definitely be returning for future projects and will be recommending them to friends and colleagues. Thank you, ACSSS, for making this experience so positive and memorable!"
  },
  {
    name: "peter",
    imgsrc: "/Images/students-with-supplies-stockcake.jpg",
    review: "One of the aspects I appreciated the most was their commitment to deadlines. The project was completed on time, and any adjustments needed were handled swiftly and effectively. Their attention to detail and dedication to meeting deadlines ensured that there were no unnecessary delays or complications."
  },
];

function Reviewspage() {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const sliderRef = useRef(null); // Create a ref for the Slider

  const handleToggle = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
    if (sliderRef.current) {
      sliderRef.current.slickPause(); // Pause autoplay when button is clicked
    }
  };

  const handleMouseEnter = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPause(); // Pause autoplay on hover
    }
  };

  const handleMouseLeave = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPlay(); // Resume autoplay when hover ends
    }
  };

  // Slick slider settings with autoplay
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true, // Enable arrows
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Set autoplay speed (3 seconds)
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 5 }}>
      <Grid container sx={{ marginBottom: "20px" }}>
        <Grid item xs={12} sm={12} lg={12} xl={12} md={12}>
          <Paper>
            <Grid
              component={"div"}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: "lightcyan",
                borderRadius: "10px",
              }}
            >
              <Typography
                variant='h5'
                sx={{
                  fontWeight: "bold",
                  padding: 3
                }}
              >
                Real Experiences, Real Results
              </Typography>
            </Grid>
            <Grid
              component={"div"}
              sx={{
                padding: 4
              }}
            >
              <Typography sx={{ textAlign: "justify" }}>
                We are proud to share the experiences and feedback from our valued clients. Their stories and satisfaction inspire us to continue delivering excellence. Read on to discover what makes us a trusted choice for many.
              </Typography>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      
      {/* Slick Slider */}
      <Slider
        {...settings}
        ref={sliderRef} // Attach the ref to the Slider
        beforeChange={handleMouseLeave} // Resume autoplay when the slide changes
      >
        {reviewsArray.map((reviewObj, index) => {
          const previewText = reviewObj.review.length > 100 ? reviewObj.review.substring(0, 100) + '...' : reviewObj.review;
          const isExpanded = index === expandedIndex;

          return (
            <Box key={reviewObj.name} sx={{ padding: 2 }}>
              <Paper elevation={0} sx={{ height: "100%", width: "100%", borderRadius: "10px" }}>
                <Box
                  component={"div"}
                  sx={{
                    bgcolor: "lightcyan",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    display: "flex",
                    width: "100%",
                    borderRadius: "10px",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <img
                    src={reviewObj.imgsrc}
                    alt={reviewObj.name}
                    style={{
                      height: "60px",
                      width: "60px",
                      borderRadius: "50%",
                      objectFit: "cover"
                    }}
                  />
                </Box>
                <Box component={"div"} sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 2 }}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {reviewObj.name}
                  </Typography>
                </Box>
                <Box component={"div"} sx={{ padding: 1, textAlign: "justify" }}>
                  <Typography>
                    {isExpanded ? reviewObj.review : previewText}
                  </Typography>
                </Box>
                <Box sx={{ padding: 1 }}>
                  <Button
                    variant='contained'
                    sx={{
                      bgcolor: "transparent",
                      color: "black",
                      width: "100%",
                      ":hover": { bgcolor: "#478d94" }
                    }}
                    disabled={reviewObj.review.length < 100}
                    onClick={() => handleToggle(index)}
                    onMouseEnter={handleMouseEnter} // Pause autoplay on hover
                  >
                    {isExpanded && reviewObj.review.length > 100 ? 'Read Less' : 'Read More'}
                  </Button>
                </Box>
              </Paper>
            </Box>
          );
        })}
      </Slider>
    </Box>
  );
}

export default Reviewspage;
